import React, { useState } from "react";

const TextOptInForm = () => {
  const initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    smsConsent: false,
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(initialFormState);

  const textClassName = "font-normal";

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "phone") {
      setFormData((prev) => ({
        ...prev,
        [name]: formatPhoneNumber(value),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = "Please enter your first name";
    if (!formData.lastName.trim())
      newErrors.lastName = "Please enter your last name";
    if (!formData.email.trim()) {
      newErrors.email = "Please enter your email";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Please enter your phone number";
    } else if (formData.phone.replace(/[^\d]/g, "").length !== 10) {
      newErrors.phone = "Please enter a valid phone number";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://hooks.zapier.com/hooks/catch/5841802/28qj9fe/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        setIsSubmitted(true);
        setFormData(initialFormState);
      } else {
        alert("Failed to submit form. Please try again.");
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
    }

    setIsSubmitting(false);
  };

  return (
    <div className="max-w-5xl mx-auto p-6 mb-48 border lg:rounded-lg lg:shadow-lg">
      <form onSubmit={handleSubmit}>
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10 my-6 font-normal">
            <div>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="First Name"
                className={`w-full p-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-primary ${
                  errors.firstName ? "border-red-500" : ""
                }`}
              />
              {errors.firstName && (
                <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>
              )}
            </div>

            <div>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
                className={`w-full p-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-primary ${
                  errors.lastName ? "border-red-500" : ""
                }`}
              />
              {errors.lastName && (
                <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>
              )}
            </div>

            <div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className={`w-full p-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-primary ${
                  errors.email ? "border-red-500" : ""
                }`}
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            <div>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone"
                maxLength="14"
                className={`w-full p-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-primary ${
                  errors.phone ? "border-red-500" : ""
                }`}
              />
              {errors.phone && (
                <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
              )}
            </div>
          </div>

          <div className="mb-2">
            <p className="font-normal text-15">
              By clicking the button below, you consent to receiving calls and
              emails from CalCon Mutual Mortgage LLC.
            </p>
          </div>

          <div className="space-y-4 mb-4">
            <div className="flex items-start space-x-2">
              <input
                type="checkbox"
                id="smsConsent"
                name="smsConsent"
                checked={formData.smsConsent}
                onChange={handleChange}
                className="h-8 w-8"
              />
              <label htmlFor="smsConsent" className="text-14 font-normal">
                I consent to receive SMS from CalCon Mutual Mortgage LLC. Reply
                STOP to opt-out; Reply HELP for support; Message and data rates
                apply; Messaging frequency may vary. Visit{" "}
                <a
                  href="https://www.calconmutualmortgage.com/privacy-policy"
                  className="text-blue-600 hover:text-blue-800"
                >
                  here
                </a>{" "}
                to see our privacy policy and{" "}
                <a
                  href="https://www.calconmutualmortgage.com/terms-of-use/"
                  className="text-blue-600 hover:text-blue-800"
                >
                  here
                </a>{" "}
                for our Terms of Service.
              </label>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            type="submit"
            disabled={isSubmitting}
            className="w-1/3 flex mx-auto my-10 items-center justify-center text-center bg-blue-primary text-white p-4 rounded-md hover:bg-blue-complimentary"
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>

          {isSubmitted && (
            <p className="mb-4 text-center text-green-button">
              Thank you for your submission!
            </p>
          )}
          <div className="text-15">
            <a
              href="https://www.calconmutualmortgage.com/privacy-policy"
              className="text-blue-600 font-semibold hover:text-blue-700 underline"
            >
              Privacy Notice
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TextOptInForm;
